
import React, { useState } from 'react';
import './Components.css';

import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';










function Nav(props) {





//() => ChangeTopic('animation'
//setProjectView(!projectview);
  return (
      <>
      <div className="reset" onClick={()=>props.navclick('')}>&#9673;</div>
      <ul className="mainMenu">
        <li search="UX" onClick={()=>props.navclick('ux')}>UX/UI</li>
        <li search="Code" onClick={()=>props.navclick('code')}>Code</li>
        <li search="Animation" onClick={()=>props.navclick('animation')}>Animation</li>
        <li search="Illustration" onClick={()=>props.navclick('illustration')}>Illustration</li>
      </ul>
      </>

  );
}











export default Nav;
