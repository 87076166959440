
import React, { useState } from 'react';
import './App.css';
import Nav from './Nav.js';
import './Components.css';





import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import CursorAnimTrail from './CursorAnimTrail';




const projects=[
{"title":"Live Event Site Animation", "buttonArt":"./assets/buttons/esributton.png", "type":"casestudy", "content":"casestudy30", "cat":"Code UX React GSAP Animation"},
{"title":"Touchless Arcade", "buttonArt":"./assets/buttons/letsrace.png", "type":"casestudy", "content":"casestudy10", "cat":"Code UX Socket-Setup"},
{"title":"PSA - AE Scripting", "buttonArt":"./assets/buttons/nodes.png", "type":"casestudy", "content":"casestudy11", "cat":"Animation Illustration Code"},
{"title":"Site / App React/React Native", "buttonArt":"./assets/buttons/dnrec-button.png", "type":"casestudy", "content":"casestudy0", "cat":"UX Code"},
{"title":"Sticker It App - Concept / Code", "buttonArt":"./assets/buttons/stickerit.png", "type":"casestudy", "content":"casestudy2", "cat":"UX Code"},
{"title":"VR - Voice Command + Gaze", "buttonArt":"./assets/buttons/linc01.png", "type":"casestudy", "content":"casestudy12", "cat":"Code UX"},
{"title":"Training Videos - 26 in Series", "buttonArt":"./assets/buttons/verifi.png", "type":"casestudy", "content":"casestudy16", "cat":"Animation UX"},
{"title":"Street Teams React-Native", "buttonArt":"./assets/buttons/streetteam.png", "type":"casestudy", "content":"casestudy1", "cat":"UX Code"},
{"title":"Social Ad/TV Spot - Animation", "buttonArt":"./assets/buttons/car.png", "type":"casestudy", "content":"casestudy14", "cat":"Animation Illustration"},
{"title":"VR Look Dev", "buttonArt":"./assets/buttons/grass.png", "type":"casestudy", "content":"casestudy13", "cat":"Animation Modeling Texturing Code"},
{"title":"Real-time Animation", "buttonArt":"./assets/buttons/mr.png", "type":"casestudy", "content":"casestudy19", "cat":"Animation"},
{"title":"PSA ", "buttonArt":"./assets/buttons/vote.png", "type":"casestudy", "content":"casestudy15","cat":"Animation Illustration"},
{"title":"Interactive Infographic", "buttonArt":"./assets/buttons/info.png", "type":"html", "content":"casestudy20", "cat":"Illustration Code"},
{"title":"Clips from Explainers", "buttonArt":"./assets/buttons/explainer.png", "type":"casestudy", "content":"casestudy18", "cat":"Animation Illustration"}];



//{"title":"WebGL Presentation Tool", "buttonArt":"./assets/buttons/webglproject.png", "type":"casestudy", "content":"casestudy17", "cat":"UX Code"},
function TopicView() {

  //instead of Router, overkill for this...
  //let location = useLocation()
  //var str=location.pathname.replace(/[\/\\]/g,'');
  var locationString=window.location.pathname.replace(/[\/]/g,'')
  return locationString;

  //setProjectView(!projectview);

}



function App() {

  const [projectview, setProjectView] = React.useState(false);
    const [content, setContent] = React.useState("");
    const [contenttype, setContenttype] = React.useState("");

    const [currentTopic, setCurrentTopic] = React.useState(window.location.pathname.replace(/[\/]/g,''));

  const casestudy="";

  function ChangeTopic(newtopic){

    setCurrentTopic(newtopic);

    window.history.replaceState("Update", newtopic, '/'+newtopic);
    //window.location.replace('/'+newtopic);
  }


  function Overlay( ){


      return(
         <div>

           {projectview?
             <div className="fullOverlay" animate="1">
               <div className="close" onClick={()=>ChangeOverlayState()}>&times;</div>
                 <div id="contentHolder">
                    <iframe frameBorder='0' seamless src={'./casestudy/'+content+'.html'} width="100%" height="100%" className='casestudy'/>
                 </div>

             </div>
             :
             null
           }
        </div>
      );
    }

   function ChangeOverlayState(projecttype, projectcontent){


      setContent(projectcontent);
      setContenttype(projecttype);
      setProjectView(!projectview);

      !projectview ? document.body.classList.add('hideScroll') :  document.body.classList.remove('hideScroll');



      window.scrollTo(0, 0);

    }



//setProjectView(!projectview);
  return (
    <>
      <Overlay visible={projectview} content={casestudy}/>


      {/*<div className="navbarbg"> </div>*/}
      <div className="gridholder">

              <Nav navclick={ChangeTopic}/>
  <CursorAnimTrail/>
              <Grid projects={projects} clicks={ChangeOverlayState} search={currentTopic}/>


      </div>
     </>

  );
}








function Grid(props){
  return (
     <div className="grid">
     <GridItems projects={props.projects} showall={false} clicks={props.clicks} search={props.search}/>
    <GridItems projects={props.projects} showall={true} clicks={props.clicks} search={props.search}/>


     </div>
  );
}



function GridItems(props){



    var locationString=props.search;

    var headline=locationString;

    if(props.showall){

      if(locationString!==""){
        locationString="";
        headline="Everything";
      }

    }


      var regString=new RegExp(locationString,"i");
      var doSearch=false;




    //props.projects.map((project, i)=>
    //    .filter(project=>project.cat.indexOf(locationString)!==-1)


  const searchArray = props.projects.filter(project=>project.cat.search(regString)!==-1);
  var searchCount = searchArray.length;



    if((locationString!="" && searchCount>0)||props.showall){
          return(
              <>

               <div className="headline">{headline}</div>


                  {

                    props.projects
                   .filter(project=>project.cat.search(regString)!==-1)
                   .map((project,i)=>

                    <div key={i} className="griditem" onClick={()=>ShowProject(props.clicks, project.type,project.content)}>

                    <img src={project.buttonArt} />
                    <div className="title">
                    {project.title}
                    </div>
                      <div className="cat">
                        {project.cat.replace(/\s/g, ' • ') }
                        </div>
                    </div>
                  )

                }
              </>
          );
        }else{
          return(null);
        }


}



function ShowProject(projectclick, projecttype, projectcontent){

    projectclick( projecttype, projectcontent);

}



export default App;
